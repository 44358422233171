const HomeContact = () => {
  return (
    <>
      <section className="project py-5">
        <div className="container">
          <div className="row">
            <div id="contact" className="col-lg-11 col-12">
              <div className="col-lg-12">
                <h2 className="text-primary">Contact Me</h2>

                <div className="contact-content">
                  <div className="contact-location">
                    <div className="contact-links">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.4em"
                          height="1.4em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M12 12q.825 0 1.413-.588Q14 10.825 14 10t-.587-1.413Q12.825 8 12 8q-.825 0-1.412.587Q10 9.175 10 10q0 .825.588 1.412Q11.175 12 12 12Zm0 9.625q-.2 0-.4-.075t-.35-.2Q7.6 18.125 5.8 15.363Q4 12.6 4 10.2q0-3.75 2.413-5.975Q8.825 2 12 2t5.587 2.225Q20 6.45 20 10.2q0 2.4-1.8 5.163q-1.8 2.762-5.45 5.987q-.15.125-.35.2q-.2.075-.4.075Z"
                          />
                        </svg>
                        <span>Yaoundé, Cameroon</span>
                      </div>

                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.4em"
                          height="1.4em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M22 4H2v16h20V4zm-2 4l-8 5l-8-5V6l8 5l8-5v2z"
                          />
                        </svg>
                        <span>Oracions.dev@gmail.com</span>
                      </div>

                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.4em"
                          height="1.4em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19 11.95q0-2.925-2.038-4.963Q14.925 4.95 12 4.95v-2q1.875 0 3.513.712q1.637.713 2.85 1.926q1.212 1.212 1.925 2.85Q21 10.075 21 11.95Zm-4 0q0-1.25-.875-2.125T12 8.95v-2q2.075 0 3.538 1.462Q17 9.875 17 11.95ZM19.95 21q-3.225 0-6.287-1.438q-3.063-1.437-5.425-3.8q-2.363-2.362-3.8-5.425Q3 7.275 3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.225t.325.575l.65 3.5q.05.35-.012.637q-.063.288-.288.513L7 10.9q1.05 1.8 2.625 3.375T13.1 17l2.35-2.35q.225-.225.588-.338q.362-.112.712-.062l3.45.7q.35.075.575.337q.225.263.225.613v4.05q0 .45-.3.75t-.75.3Z"
                          />
                        </svg>
                        <span>+(237) 652-033-708</span>
                      </div>

                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.4em"
                          height="1.4em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19 11.95q0-2.925-2.038-4.963Q14.925 4.95 12 4.95v-2q1.875 0 3.513.712q1.637.713 2.85 1.926q1.212 1.212 1.925 2.85Q21 10.075 21 11.95Zm-4 0q0-1.25-.875-2.125T12 8.95v-2q2.075 0 3.538 1.462Q17 9.875 17 11.95ZM19.95 21q-3.225 0-6.287-1.438q-3.063-1.437-5.425-3.8q-2.363-2.362-3.8-5.425Q3 7.275 3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.225t.325.575l.65 3.5q.05.35-.012.637q-.063.288-.288.513L7 10.9q1.05 1.8 2.625 3.375T13.1 17l2.35-2.35q.225-.225.588-.338q.362-.112.712-.062l3.45.7q.35.075.575.337q.225.263.225.613v4.05q0 .45-.3.75t-.75.3Z"
                          />
                        </svg>
                        <span>+(237) 656-914-671</span>
                      </div>
                    </div>

                    <p className="social-links mt-4">
                      <span>
                        <a
                          href="https://github.com/will-oracions"
                          target="_blank"
                          rel="noreferrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.6em"
                            height="1.6em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33c.85 0 1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2Z"
                            />
                          </svg>
                        </a>
                      </span>

                      <span>
                        <a
                          href="https://twitter.com/will_oracions"
                          target="_blank"
                          rel="noreferrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 273.5 222.3"
                            role="img"
                            aria-labelledby="ooq92vvwgg1j0cjya0fitgk1o2fr6uv"
                            className="flex-shrink-0 octicon"
                            width="1.6em"
                            height="1.6em">
                            <title id="ooq92vvwgg1j0cjya0fitgk1o2fr6uv">
                              Twitter
                            </title>
                            <path
                              d="M273.5 26.3a109.77 109.77 0 0 1-32.2 8.8 56.07 56.07 0 0 0 24.7-31 113.39 113.39 0 0 1-35.7 13.6 56.1 56.1 0 0 0-97 38.4 54 54 0 0 0 1.5 12.8A159.68 159.68 0 0 1 19.1 10.3a56.12 56.12 0 0 0 17.4 74.9 56.06 56.06 0 0 1-25.4-7v.7a56.11 56.11 0 0 0 45 55 55.65 55.65 0 0 1-14.8 2 62.39 62.39 0 0 1-10.6-1 56.24 56.24 0 0 0 52.4 39 112.87 112.87 0 0 1-69.7 24 119 119 0 0 1-13.4-.8 158.83 158.83 0 0 0 86 25.2c103.2 0 159.6-85.5 159.6-159.6 0-2.4-.1-4.9-.2-7.3a114.25 114.25 0 0 0 28.1-29.1"
                              fill="currentColor"></path>
                          </svg>
                        </a>
                      </span>

                      <span>
                        <a href="mailto:oracions.dev@gmail.com">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.6em"
                            height="1.6em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M22 4H2v16h20V4zm-2 4l-8 5l-8-5V6l8 5l8-5v2z"
                            />
                          </svg>
                        </a>
                      </span>

                      <span>
                        <a
                          href="https://www.linkedin.com/in/louis-mary-monjengue-a73214182"
                          target="_blank"
                          rel="noreferrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.6em"
                            height="1.6em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z"
                            />
                          </svg>
                        </a>
                      </span>

                      <span>
                        <a
                          href="https://wa.me/+237652033708"
                          target="_blank"
                          rel="noreferrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.6em"
                            height="1.6em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 256 258">
                            <defs>
                              <linearGradient
                                id="svgIDa"
                                x1="50%"
                                x2="50%"
                                y1="100%"
                                y2="0%">
                                <stop offset="0%" stop-color="#1FAF38" />
                                <stop offset="100%" stop-color="#60D669" />
                              </linearGradient>
                              <linearGradient
                                id="svgIDb"
                                x1="50%"
                                x2="50%"
                                y1="100%"
                                y2="0%">
                                <stop offset="0%" stop-color="#F9F9F9" />
                                <stop offset="100%" stop-color="#FFF" />
                              </linearGradient>
                            </defs>
                            <path
                              fill="url(#svgIDa)"
                              d="M5.463 127.456c-.006 21.677 5.658 42.843 16.428 61.499L4.433 252.697l65.232-17.104a122.994 122.994 0 0 0 58.8 14.97h.054c67.815 0 123.018-55.183 123.047-123.01c.013-32.867-12.775-63.773-36.009-87.025c-23.23-23.25-54.125-36.061-87.043-36.076c-67.823 0-123.022 55.18-123.05 123.004"
                            />
                            <path
                              fill="url(#svgIDb)"
                              d="M1.07 127.416c-.007 22.457 5.86 44.38 17.014 63.704L0 257.147l67.571-17.717c18.618 10.151 39.58 15.503 60.91 15.511h.055c70.248 0 127.434-57.168 127.464-127.423c.012-34.048-13.236-66.065-37.3-90.15C194.633 13.286 162.633.014 128.536 0C58.276 0 1.099 57.16 1.071 127.416Zm40.24 60.376l-2.523-4.005c-10.606-16.864-16.204-36.352-16.196-56.363C22.614 69.029 70.138 21.52 128.576 21.52c28.3.012 54.896 11.044 74.9 31.06c20.003 20.018 31.01 46.628 31.003 74.93c-.026 58.395-47.551 105.91-105.943 105.91h-.042c-19.013-.01-37.66-5.116-53.922-14.765l-3.87-2.295l-40.098 10.513l10.706-39.082Z"
                            />
                            <path
                              fill="#FFF"
                              d="M96.678 74.148c-2.386-5.303-4.897-5.41-7.166-5.503c-1.858-.08-3.982-.074-6.104-.074c-2.124 0-5.575.799-8.492 3.984c-2.92 3.188-11.148 10.892-11.148 26.561c0 15.67 11.413 30.813 13.004 32.94c1.593 2.123 22.033 35.307 54.405 48.073c26.904 10.609 32.379 8.499 38.218 7.967c5.84-.53 18.844-7.702 21.497-15.139c2.655-7.436 2.655-13.81 1.859-15.142c-.796-1.327-2.92-2.124-6.105-3.716c-3.186-1.593-18.844-9.298-21.763-10.361c-2.92-1.062-5.043-1.592-7.167 1.597c-2.124 3.184-8.223 10.356-10.082 12.48c-1.857 2.129-3.716 2.394-6.9.801c-3.187-1.598-13.444-4.957-25.613-15.806c-9.468-8.442-15.86-18.867-17.718-22.056c-1.858-3.184-.199-4.91 1.398-6.497c1.431-1.427 3.186-3.719 4.78-5.578c1.588-1.86 2.118-3.187 3.18-5.311c1.063-2.126.531-3.986-.264-5.579c-.798-1.593-6.987-17.343-9.819-23.64"
                            />
                          </svg>
                        </a>
                      </span>
                    </p>
                  </div>

                  <div className="map">
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <iframe
                          title="profile location"
                          width="316"
                          height="234"
                          id="gmap_canvas"
                          src={`https://maps.google.com/maps?q=yaound%C3%A9,%20ngoa%20%C3%A9k%C3%A9l%C3%A9&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"></iframe>
                        <a
                          href="https://fmovies-online.net"
                          target="_blank"
                          rel="noreferrer">
                          .
                        </a>
                        <br />
                        <a href="https://www.embedgooglemap.net">
                          how to add a google map to html
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <form>
                    <div className="row">
                      <div className="col-md-6 col-6 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          placeholder="Firstname"
                        />
                      </div>

                      <div className="col-md-6 col-6 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          placeholder="Lastname"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email address"
                      />
                    </div>

                    <div className="form-group">
                      <textarea
                        rows="5"
                        className="form-control"
                        placeholder="Type your message here"></textarea>
                    </div>

                    <div>
                      <a
                        href="#contact"
                        className="btn custom-btn custom-btn-bg custom-btn-link">
                        Send Message
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeContact;
